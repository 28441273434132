import React, { useState } from 'react'
import { Link } from 'gatsby'
import Modal from './Modal'
import product1 from '../img/products/1.jpeg'
import product3 from '../img/products/3.jpeg'
import product8 from '../img/products/8.jpg'
import product7 from '../img/products/7.jpg'
import product5 from '../img/products/5.jpeg'
import product9 from '../img/products/9.jpg'

export default function ProductsList() {
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <>
      <ul className="list products-list">
        <li className="item">
          <Link
            className="link"
            to="#"
            onClick={() => {
              setModalOpen(true)
            }}
          >
            <figure>
              <img src={product1} alt="екоративні рвані та гладкі блоки" className="img" />
              <figcaption>Декоративні <br /> рвані та гладкі блоки</figcaption>
            </figure>
          </Link>
        </li>
        <li className="item">
          <Link
            className="link"
            to="#"
            onClick={() => {
              setModalOpen(true)
            }}
          >
            <figure>
              <img src={product9} alt="екоративні рвані та гладкі блоки" className="img" />
              <figcaption>Колормікс блоки та цегла</figcaption>
            </figure>
          </Link>
        </li>


        <li className="item">
          <Link
            className="link"
            to="#"
            onClick={() => {
              setModalOpen(true)
            }}
          >
            <figure>
              <img src={product8} alt="Жалюзі" className="img" />
              <figcaption>Жалюзі</figcaption>
            </figure>
          </Link>
        </li>
        <li className="item">
          <Link
            className="link"
            to="#"
            onClick={() => {
              setModalOpen(true)
            }}
          >
            <figure>
              <img
                src={product7}
                alt="Швидкомонтажні клінкерні стовпчики"
                className="img"
              />
              <figcaption>Швидкомонтажні клінкерні стовпчики</figcaption>
            </figure>
          </Link>
        </li>

        <li className="item">
          <Link
            className="link"
            to="#"
            onClick={() => {
              setModalOpen(true)
            }}
          >
            <figure>
              <img src={product3} alt="Металеві штахети" className="img" />
              <figcaption>Металеві штахети</figcaption>
            </figure>
          </Link>
        </li>
        <li className="item">
          <Link
            className="link"
            to="#"
            onClick={() => {
              setModalOpen(true)
            }}
          >
            <figure>
              <img src={product5} alt="Ранчо" className="img" />
              <figcaption>Ранчо</figcaption>
            </figure>
          </Link>
        </li>
      </ul>
      {modalOpen && <Modal setOpenModal={setModalOpen} />}
    </>
  )
}
