import * as React from 'react'
import Form from './Form'

function Modal({ setOpenModal }) {
  return (
    <>
      <div className="backdrop" data-modal>
        <div className="modal">
          <button
            className="btn-close"
            type="button"
            data-modal-close
            aria-label="Закрити"
            onClick={() => {
              setOpenModal(false)
            }}
          ></button>
          <Form />
        </div>
      </div>
    </>
  )
}

export default Modal
