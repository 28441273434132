import React, { useState } from 'react'
import svgCheck from '../img/icons/checkmark.svg'
import mainLogo from '../img/logo1.svg'
import ContactUs from './ContactUs'
import Modal from './Modal'

export default function Hero(props) {
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <>
      <div className="mobile-contacts">
        <div className="container">
          <ul className="list contacts">
            <li className="item">
              <a href="tel:+380973861494" className="link">
                (097)386-14-94
              </a>
            </li>
            <li className="item">
              <a href="tel:+380933488899" className="link">
                (093)348-88-99
              </a>
            </li>
          </ul>
        </div>
      </div>
      <header className="hero">
        <div className="container">
          <div className="top-panel">
            <img src={mainLogo} alt="" className="logo-img" width="240" />

            <ul className="list contacts">
              <li className="item">
                <a href="tel:+380973861494" className="link">
                  (097)386-14-94
                </a>
              </li>
              <li className="item">
                <a href="tel:+380933488899" className="link">
                  (093)348-88-99
                </a>
              </li>
            </ul>
          </div>
          <div className="hero-content">
            <h1 className="title page-title">
              Ідеальний паркан
              <br />
              для вашого будинку
            </h1>
            <ul className="list hero-list">
              <li className="item">
                <img
                  src={svgCheck}
                  alt=""
                  className="icon"
                  width="18"
                  height="18"
                />
                міцний
              </li>
              <li className="item">
                <img
                  src={svgCheck}
                  alt=""
                  className="icon"
                  width="18"
                  height="18"
                />
                надійний
              </li>
              <li className="item">
                <img
                  src={svgCheck}
                  alt=""
                  className="icon"
                  width="18"
                  height="18"
                />
                довговічний
              </li>
              <li className="item">
                <img
                  src={svgCheck}
                  alt=""
                  className="icon"
                  width="18"
                  height="18"
                />
                красивий
              </li>
            </ul>
            <div>Замовляйте безкоштовну консультацію прямо зараз!</div>

            <button
              className="btn btn-modal"
              onClick={() => {
                setModalOpen(true)
              }}
            >
              Замовити консультацію
            </button>
          </div>
        </div>
      </header>
      {modalOpen && <Modal setOpenModal={setModalOpen} />}
      <section className="section offer">
        <div className="container">
          <div className="hero-contact-us">
            <p>
              Або зв’яжіться з нами прямо зараз зручним для вас способом. І ми
              підберемо найкращий варіант саме для вас та прорахуємо необхідну
              кількість матеріалів.
            </p>
            <ContactUs />
          </div>
        </div>
      </section>
    </>
  )
}
