import React, { useState } from 'react'
import ContactUs from './ContactUs'
import Modal from './Modal'

export default function Why(params) {
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <>
      <section className="section why">
        <div className="container">
          <h2 className="title section-title">
            Чому вам варто звернутися до нас?
          </h2>
          <ul className="list why-list">
            <li className="item">
              Ми знаємо усі нюанси та лайфхаки монтажу парканів і обов’язково
              надамо всі необхідні консультації. А отже жодних проблем з
              монтажем не виникне.
            </li>
            <li className="item">
              Ми прорахуємо вам необхідну кількість матеріалів так, щоб точно
              вистачило і не залишилось багато зайвого.
            </li>
            <li className="item">
              Наші паркани легко монтувати, можна комбінувати секції та стовпці
              з різних матеріалів.
            </li>
            <li className="item">
              Якщо ви хочете побачити, як виглядатиме паркан вживу, можете
              завітати до нашого виставкового майданчика за адресою: м. Київ, м.
              Лісова, вул. Мурманська, 6.
            </li>
          </ul>
          <p>
            Замовляйте безкоштовну консультацію і ми відповімо на всі ваші
            запитання.
          </p>

          <button
            className="btn btn-modal"
            onClick={() => {
              setModalOpen(true)
            }}
          >
            Замовити консультацію
          </button>
        </div>
      </section>
      {modalOpen && <Modal setOpenModal={setModalOpen} />}
      <section className="section offer">
        <div className="container">
          <div className="hero-contact-us">
            <p>Або зв’яжіться з нами прямо зараз зручним для вас способом</p>
            <ContactUs />
          </div>
        </div>
      </section>
    </>
  )
}
