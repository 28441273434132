import * as React from 'react'
import svgTelegram from '../img/icons/telegram.svg'
import svgWhatsApp from '../img/icons/whatsapp.svg'
import svgViber from '../img/icons/viber.svg'
import svgPhone from '../img/icons/phone.svg'

const ContactUs = class extends React.Component {
  render() {
    return (
      <ul className="list contact-us">
        <li className="item">
          <a
            href="tel:+380973861494"
            className="link link-phone"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            <img
              src={svgPhone}
              alt="Телефон"
              className="icon"
              width="32"
              height="32"
            />
          </a>
        </li>
        <li className="item">
          <a
            href="viber://chat?number=%2B380933488899"
            className="link link-viber"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            <img
              src={svgViber}
              alt="Вайбер"
              className="icon"
              width="32"
              height="32"
            />
          </a>
        </li>
        <li className="item">
          <a
            href="https://t.me/Parkanhouse"
            className="link link-telegram"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            <img
              src={svgTelegram}
              alt="Телеграм"
              className="icon"
              width="32"
              height="32"
            />
          </a>
        </li>
        <li className="item">
          <a
            href="https://wa.me/380973861494"
            className="link link-whatsapp"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            <img
              src={svgWhatsApp}
              alt="Ватсап"
              className="icon"
              width="32"
              height="32"
            />
          </a>
        </li>
      </ul>
    )
  }
}

export default ContactUs
