import * as React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import ContactUs from './ContactUs'
import ProductsList from './ProductsList'
import file from '../pages/test.json'

export default function OurProducts() {
  return (
    <>
      <section className="section products">
        <div className="container">
          <h2 className="title section-title">
            Хочете встановити чи замінити паркан навколо свого будинку або
            земельної ділянки?
          </h2>
          <p className="text-accent">Тоді ви там, де треба!</p>

          <p>У нас широкий вибір парканів з різних матеріалів:</p>
        </div>
        <ProductsList />
        <div className="container">
          <p>
            Ми слідкуємо за тенденціями та постійно додаємо до асортименту
            новинки ринку.
          </p>
          <div className="btn-wrap">
            <a
              href={file.manual_pdf}
              className="btn"
              target="_blank"
              rel="noopenere noreferrer nofollow"
              download
            >
              Що почому?!
            </a>

            <Link to="/products" className="btn btn-accent">
              Хочете побачити більше продукції ?
            </Link>
          </div>
        </div>
      </section>
      <section className="section offer">
        <div className="container">
          <div className="hero-contact-us">
            <p>
              Якщо вам потрібен
              <strong> просто хороший паркан</strong>, ми залюбки
              проконсультуємо та допоможемо визначитись з парканом саме для
              вашого будинку.
            </p>
            <ContactUs />
          </div>
        </div>
      </section>
    </>
  )
}

OurProducts.propTypes = {
  file: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}
