import React from 'react'
import PropTypes from 'prop-types'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import OurProducts from '../components/OurProducts'
import Why from '../components/Why'

// eslint-disable-next-line
export const IndexPageTemplate = ({ url }) => {
  return (
    <div>
      <Hero />
      <OurProducts />
      <Why />
    </div>
  )
}

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <IndexPageTemplate />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage
