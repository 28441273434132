import React, { useState } from 'react'
import { navigate } from 'gatsby-link'

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const Form = () => {
  const [name, setName] = useState('')

  const handleChange = (e) => {
    setName({ ...name, [e.target.name]: e.target.value })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': event.target.getAttribute('name'),
        ...name,
      }),
    })
      .then(() => navigate('/thanks/'))
      .catch((error) => alert(error))
  }

  return (
    <form
      name="leadform"
      className="form"
      method="POST"
      action="/thanks/"
      data-netlify="true"
      netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
    >
      <input name="form-name" value="leadform" type="hidden" />
      <label className="form-label">
        <input
          className="input form-field"
          type="text"
          name="name"
          id="name"
          required
          placeholder="Ваше ім'я"
          onChange={handleChange}
        />
      </label>
      <label className="form-label">
        <input
          className="input form-field"
          type="tel"
          name="tel"
          id="tel"
          required
          placeholder="Номер телефону"
          onChange={handleChange}
        />
      </label>

      <button className="btn btn-submit" type="submit" id="submit">
        Відпривати заявку
      </button>
    </form>
  )
}

export default Form
